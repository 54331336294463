body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.martop5{
  margin-top: 20px;
}
 

.datepicker-container {
  width: 100%;
  height: 50px;
  
}

/* Ensure the container of the date picker is 100% width */
.react-datepicker-wrapper {
  width: 100%;
  height: 50px;

}

.react-datepicker__input-container {
  width: 100%;
  height: 50px;

}
 

/* Adjust the input field if necessary */
.react-datepicker__input-container input {
  width: 100%;
  height: 50px;

}
